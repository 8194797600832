import React from 'react';
import { shape } from 'prop-types';
import { loadable, LazyHydrate as Hydrator } from 'nordic/lazy';
import View from '../view';

// Head Section
import CompatsWidgetSticky from '../../features/compats-widget-sticky/compats-widget-sticky.mobile';

import CompatsWidget from '../../features/compats-widget/compats-widget.desktop';
import CPG from '../../features/cpg/header-cpg.mobile';
import Onboarding from '../../features/onboarding/onboarding.mobile';
import ItemStatusHeadCompats from '../../features/item-status/head.compats.mobile';
import ThirdPartyFirstSection from '../../features/third_party_first_section';
import ThirdPartySecondSection from '../../features/third_party_second_section';
import Pills from '../../features/pills';
import Gallery from '../../features/gallery/gallery.mobile';
import FullscreenAd from '../../features/fullscreen-ad';
import Breadcrumb from '../../features/breadcrumb';
import MoqInformation from '../../features/moq-information/moq-information.mobile';
import BillboardAdn from '../../features/billboard-adn/billboard-adn-bottom';
import OnDemandIframe from '../../features/on-demand-iframe/on-demand-iframe';
import Grid from '../../../../components/grid';

const ItemStatusMessageHead = loadable(() => import('../../features/item-status/head'));
const RecommendationsCarouselFree = loadable(() => import('../../features/recommendations/carousel-free'));
const RecommendationsCarouselFreeHorizontal = loadable(() =>
  import('../../features/recommendations/carousel-free-horizontal'),
);
const RecommendationsListDouble = loadable(() => import('../../features/recommendations/list-double'));
const RecommendationsCarouselByType = loadable(() => import('../../features/recommendations/carousel-byType'));

// Hero
const Header = loadable(() => import('../../features/header/header.mobile'));

const Variations = loadable(() => import('../../features/variations/variations.mobile'));
const VariationsBox = loadable(() => import('../../features/variations/variations-box.mobile'));
const ApparealSizeSpec = loadable(() => import('../../features/appareal-size-spec/apparel-size-spec.mobile'));

// Short Description
const Price = loadable(() => import('../../features/price/price.mobile'));
const PriceExtras = loadable(() => import('../../features/price-extras/price-extras.mobile'));
const Promotions = loadable(() => import('../../features/promotions/promotions'));
const PaymentSummary = loadable(() => import('../../features/payment-summary/payment-summary.mobile'));
const CpgSummary = loadable(() => import('../../features/cpg/summary.mobile'));
const ShippingSummary = loadable(() => import('../../features/shipping-summary/shipping-summary.mobile'));
const SecondShippingSummary = loadable(() =>
  import('../../features/second-shipping-summary/second-shipping-summary.mobile'),
);
const PickUpSummary = loadable(() => import('../../features/pick-up-summary/pick-up-summary.mobile'));
const ReturnsSummary = loadable(() => import('../../features/returns-summary/returns-summary.mobile'));
const ItemStatusShortDescription = loadable(() => import('../../features/item-status/short-description'));
const Seller = loadable(() => import('../../features/seller/seller.mobile'));

const StockInformation = loadable(() => import('../../features/stock-information/stock-information.mobile'));
const AvailableQuantity = loadable(() => import('../../features/available-quantity/available-quantity.mobile'));
const PaypalTooltip = loadable(() => import('../../features/tooltips/paypal-tooltip'));
const MainActions = loadable(() => import('../../features/main-actions/main-actions.mobile'));
const CartActions = loadable(() => import('../../features/cart-actions/cart-actions.mobile'));
const CreditsTooltip = loadable(() => import('../../features/tooltips/credits-tooltip'));
const ShippingCartBenefitsTooltip = loadable(() => import('../../features/tooltips/shipping-cart-benefits-tooltip'));
const LoyaltyFreeShippingTooltip = loadable(() => import('../../features/tooltips/loyalty-tooltip'));
const BuyBenefits = loadable(() => import('../../features/buy-benefits/buy-benefits.mobile'));

// Features, Description, Returns & Box Components
const AllFeatures = loadable(() => import('../../features/all-features/all-features.mobile'));
const HighlightedSpecsAttrs = loadable(() => import('../../features/highlighted-specs/highlighted-specs-attrs.mobile'));
const Description = loadable(() => import('../../features/description/description.mobile'));
const ApparelSizeSpecsPreview = loadable(() =>
  import('../../features/apparel-size-specs-preview/apparel-size-specs-preview.mobile'),
);
// Questions, Reviews
const Reviews = loadable(() => import('../../features/reviews/reviews.mobile'));
const ReviewsCapability = loadable(() => import('../../../../components/reviews-capability/reviews.mobile'));

// Content Bottom
const RecommendationsCombos = loadable(() => import('../../../../components/combos/combos.mobile'));

const CoreMobile = props => (
  <View {...props} vertical="mshops">
    <div id="loading" />
    <Grid.Container className="ui-pdp-container--top shops-pdp-container--top">
      {/* Head Section */}
      <Hydrator whenVisible>
        <CompatsWidgetSticky />
        <ItemStatusHeadCompats />
      </Hydrator>
      <OnDemandIframe />
      <Onboarding />
      <CPG />
      <Hydrator whenVisible>
        <ItemStatusMessageHead />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsCarouselFreeHorizontal
          featureName="carousel_cheaper"
          className="ui-pdp-carousel-cheaper"
          lazy={false}
          preload
          fetchPriority="high"
          criticalImages={1}
        />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsCarouselFree
          featureName="carousel_v2p_not_active_item"
          className="ui-pdp-carousel-not-active"
          lazy={false}
          preload
          fetchPriority="high"
          criticalImages={1}
        />
      </Hydrator>
      {/* Hero */}
      <Hydrator whenIdle>
        <Header />
      </Hydrator>
      <Pills />
      <Gallery />
      <Hydrator whenVisible>
        <CompatsWidget position="below_gallery" />
      </Hydrator>
      <Hydrator whenVisible>
        <VariationsBox />
      </Hydrator>
      <Hydrator whenVisible>
        <ApparealSizeSpec className="mt-16" />
      </Hydrator>
      <Hydrator whenVisible>
        <ThirdPartyFirstSection />
      </Hydrator>
      <Hydrator whenVisible>
        <Variations />
      </Hydrator>
      {/* Short Description */}
      <Hydrator whenVisible>
        <Price />
      </Hydrator>
      <Hydrator whenVisible>
        <Promotions />
      </Hydrator>
      <Hydrator whenVisible>
        <CpgSummary />
      </Hydrator>
      <Hydrator whenVisible>
        <PaymentSummary />
      </Hydrator>
      <Hydrator whenVisible>
        <ShippingSummary />
      </Hydrator>
      <Hydrator whenVisible>
        <SecondShippingSummary />
      </Hydrator>
      <Hydrator whenVisible>
        <PickUpSummary />
      </Hydrator>
      <Hydrator whenVisible>
        <ReturnsSummary />
      </Hydrator>
      <Hydrator whenVisible>
        <ItemStatusShortDescription />
      </Hydrator>
      <Hydrator whenVisible>
        <Seller />
      </Hydrator>
      <Hydrator whenVisible>
        <CompatsWidget position="below_seller" default />
      </Hydrator>
      <Hydrator whenVisible>
        <div className="ui-pdp-container__row--stock-and-full">
          <StockInformation />
        </div>
      </Hydrator>
      <Hydrator whenVisible>
        <PriceExtras />
      </Hydrator>
      <Hydrator whenVisible>
        <AvailableQuantity />
      </Hydrator>
      <Hydrator whenVisible>
        <MoqInformation />
      </Hydrator>
      <Hydrator whenVisible>
        <PaypalTooltip />
      </Hydrator>
      <Hydrator whenVisible>
        <MainActions />
      </Hydrator>
      <Hydrator whenVisible>
        <CartActions />
      </Hydrator>
      <Hydrator whenVisible>
        <CreditsTooltip />
      </Hydrator>
      <Hydrator whenVisible>
        <ShippingCartBenefitsTooltip />
      </Hydrator>
      <Hydrator whenVisible>
        <LoyaltyFreeShippingTooltip />
      </Hydrator>
      <Hydrator whenVisible>
        <BuyBenefits />
      </Hydrator>
      {/* Recommendations */}
      <Hydrator whenVisible>
        <RecommendationsCombos />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsCarouselByType
          featureName="carousel_v2p"
          className="ui-box-component ui-box-component-carousel-by-type"
        />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsCarouselFree
          featureName="carousel_up_web"
          className="ui-box-component ui-box-component-carousel-free"
        />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsCarouselFree
          featureName="carousel_above_pads"
          className="ui-box-component ui-box-component-carousel-free"
        />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsListDouble
          featureName="carousel_above"
          className="ui-box-component ui-box-component-list-double"
        />
      </Hydrator>

      {/* Features, Merch, Description, Returns & Box Components */}
      <Hydrator whenVisible>
        <AllFeatures />
      </Hydrator>
      <Hydrator whenVisible>
        <HighlightedSpecsAttrs />
      </Hydrator>
      <Hydrator whenVisible>
        <Description className="pl-16 pr-16" />
      </Hydrator>
      <Hydrator whenVisible>
        <ThirdPartySecondSection />
      </Hydrator>
      <Hydrator whenVisible>
        <ApparelSizeSpecsPreview />
      </Hydrator>
      <Hydrator whenVisible>
        <Reviews />
      </Hydrator>
      <Hydrator whenVisible>
        <ReviewsCapability />
      </Hydrator>
      <Hydrator whenIdle>
        <FullscreenAd />
      </Hydrator>
      <Hydrator ssrOnly>
        <BillboardAdn />
      </Hydrator>
      <Hydrator whenIdle>
        <RecommendationsCarouselByType featureName="grid_v2p" className="ui-pdp-carousel-grid-bottom" />
      </Hydrator>
      <Hydrator whenVisible>
        <Breadcrumb mode="compact" />
      </Hydrator>
    </Grid.Container>
  </View>
);

CoreMobile.propTypes = {
  initialState: shape().isRequired,
};

export default CoreMobile;
